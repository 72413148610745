<template>
  <b-card>
    <ValidationObserver>
      <form @submit.prevent="saveItem">
        <b-row>
          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="phone_number">Phone Number:</label>
              <validation-provider
                name="phone_number"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="phone_number"
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formPayload.msisdn"
                  placeholder="08**********"
                />
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="email">Email:</label>
              <input
                id="email"
                type="text"
                class="form-control"
                v-model="formPayload.email"
                placeholder="jane@suwun.co.id"
              />
            </div>
            <div class="form-group">
              <label for="password">Password:</label>
              <input
                id="password"
                type="password"
                class="form-control"
                v-model="formPayload.password"
                placeholder="Password"
              />
            </div>
            <div class="form-group">
              <label for="">Profile Picture:</label><br />
              <validation-provider name="image" rules="required">
                <b-form-file
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept=".jpeg, .png, .jpg"
                  @change="changeFile($event)"
                />
                <p class="image__hint mb-1"><i>Max 5 mb</i></p>
                <small>Current file:</small><br />
                <img
                  crossorigin="anonymous"
                  v-if="
                    ((file_url !== null && file_type == 'image') || formPayload.profilePicture) ||
                    file_type == 'jpeg' ||
                    file_type == 'png' ||
                    file_type == 'jpg'
                  "
                  :src="file_url === null ? formPayload.profilePicture: file_url"
                  class="media_file"
                />
              </validation-provider>
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="name">Name:</label>
              <validation-provider
                name="name"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="name"
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formPayload.name"
                  placeholder="Name"
                />
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="gender">Gender:</label>
              <v-select
                id="gender"
                label="title"
                v-model="formPayload.gender"
                :options="option"
                placeholder="-- Pilih Gender --"
                :reduce="(option) => option.value"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
            </div>
            <div class="form-group">
              <label for="pob">Place of Birth:</label>
              <input
                id="pob"
                type="text"
                class="form-control"
                v-model="formPayload.placeOfBirth"
                placeholder="Place of Birth"
              />
            </div>
            <div class="form-group">
              <label for="dob">Date of Birth:</label>
              <flat-pickr
                id="dob"
                v-model="formPayload.dob"
                class="form-control"
                placeholder="Select Date..."
                :config="{ dateFormat: 'Y-m-d' }"
              />
            </div>
            <!-- <div class="form-group">
              <label for="proposalNotification">Proposal Notification:</label>
              <div class="demo-inline-spacing" style="margin-top: 8px">
                <div class="custom-control custom-radio mt-0">
                  <input
                    name="proposalNotification"
                    class="custom-control-input"
                    type="radio"
                    id="radio1"
                    value="true"
                    v-model="formPayload.isIncomingProposalNotificationActive"
                  />
                  <label class="custom-control-label" for="radio1"> Yes </label>
                </div>
                <div class="custom-control custom-radio mt-0">
                  <input
                    name="proposalNotification"
                    class="custom-control-input"
                    type="radio"
                    id="radio2"
                    value="false"
                    v-model="formPayload.isIncomingProposalNotificationActive"
                  />
                  <label class="custom-control-label" for="radio2"> No </label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="messageNotification">Message Notification:</label>
              <div class="demo-inline-spacing" style="margin-top: 8px">
                <div class="custom-control custom-radio mt-0">
                  <input
                    name="messageNotification"
                    class="custom-control-input"
                    type="radio"
                    id="radio3"
                    value="true"
                    v-model="formPayload.isIncomingMessageNotificationActive"
                  />
                  <label class="custom-control-label" for="radio3"> Yes </label>
                </div>
                <div class="custom-control custom-radio mt-0">
                  <input
                    name="messageNotification"
                    class="custom-control-input"
                    type="radio"
                    id="radio4"
                    value="false"
                    v-model="formPayload.isIncomingMessageNotificationActive"
                  />
                  <label class="custom-control-label" for="radio4"> No </label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="completedWorkNotification"
                >Completed Work Notification:</label
              >
              <div class="demo-inline-spacing" style="margin-top: 8px">
                <div class="custom-control custom-radio mt-0">
                  <input
                    name="completedWorkNotification"
                    class="custom-control-input"
                    type="radio"
                    id="radio5"
                    value="true"
                    v-model="formPayload.isCompletedWorkNotificationActive"
                  />
                  <label class="custom-control-label" for="radio5"> Yes </label>
                </div>
                <div class="custom-control custom-radio mt-0">
                  <input
                    name="completedWorkNotification"
                    class="custom-control-input"
                    type="radio"
                    id="radio6"
                    value="false"
                    v-model="formPayload.isCompletedWorkNotificationActive"
                  />
                  <label class="custom-control-label" for="radio6"> No </label>
                </div>
              </div>
            </div> -->
          </b-col>
        </b-row>

        <b-col cols="12" class="p-0 mt-2">
          <button
            variant="primary"
            class="btn waves-effect waves-float waves-light btn-primary"
            :disabled="isLoading"
          >
            {{ isLoading ? "Submitting..." : "Submit" }}
          </button>
          &nbsp; &nbsp;
          <a
            @click="handleBackButton()"
            variant="secondary"
            class="btn waves-effect waves-float waves-light btn-secondary"
          >
            Cancel
          </a>
        </b-col>
      </form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import {
  BCard,
  BFormGroup,
  BFormRadio,
  BRow,
  BCol,
  BFormCheckbox,
  BFormFile,
} from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import { successNotification, errorNotification } from "@/auth/utils";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    BFormFile,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  data() {
    return {
      required,
      isLoading: false,
      formPayload: {
        name: "",
        msisdn: "",
        email: "",
        password: "",
        placeOfBirth: "",
        dob: "",
        gender: "",
        isIncomingProposalNotificationActive: null,
        isIncomingMessageNotificationActive: null,
        isCompletedWorkNotificationActive: null,
        profilePicture: "",
      },
      option: [
        { title: "Male", value: "male" },
        { title: "Female", value: "female" },
      ],
      file_url: null,
      file_type: "",
      validations: "",
      // Must be an array reference!
    };
  },
  created() {
    this.loadUser();
  },
  methods: {
    handleBackButton() {
      history.back();
    },
    changeFile(event) {
      this.formPayload.profilePicture = event.target.files[0];
      this.showFile(event.target.files[0]);
    },
    showFile(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.file_url = e.target.result;
        var str = e.target.result;
        var startIndex = str.indexOf("/") + 1; // Menentukan indeks mulai setelah karakter '/'
        var endIndex = str.indexOf(";"); // Menentukan indeks akhir sebelum karakter ';'
        var imageType = str.substring(startIndex, endIndex); // Memotong string berdasarkan indeks mulai dan akhir
        this.file_type = imageType;
      };
      reader.readAsDataURL(file);
    },
    loadUser() {
      const params = this.$route.params.id;
      this.$http.get("/admin/user/" + params).then((response) => {
        this.formPayload = response.data.data;
      });
    },
    saveItem() {
      const params = this.$route.params.id;
      this.isLoading = true;
      const payload = new FormData();
      payload.append("name", this.formPayload.name);
      payload.append("msisdn", this.formPayload.msisdn);
      if (this.formPayload.email) {
        payload.append("email", this.formPayload.email);
      }
      if (this.formPayload.password) {
        payload.append("password", this.formPayload.password);
      }
      if (this.formPayload.placeOfBirth) {
        payload.append("placeOfBirth", this.formPayload.placeOfBirth);
      }
      if (this.formPayload.dob) {
        payload.append("dob", this.formPayload.dob);
      }
      if (this.formPayload.gender) {
        payload.append("gender", this.formPayload.gender);
      }
      if (this.formPayload.profilePicture) {
        payload.append("profilePicture", this.formPayload.profilePicture);
      }
      // payload.append(
      //   "isIncomingProposalNotificationActive",
      //   this.formPayload.isIncomingProposalNotificationActive
      // );
      // payload.append(
      //   "isIncomingMessageNotificationActive",
      //   this.formPayload.isIncomingMessageNotificationActive
      // );
      // payload.append(
      //   "isCompletedWorkNotificationActive",
      //   this.formPayload.isCompletedWorkNotificationActive
      // );
      this.$http
        .put(`/admin/user/${params}`, payload)
        .then((response) => {
          this.isLoading = false;
          successNotification(this, "Success", "User sukses diupdate!");
          this.$router.push({ name: "users" });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.meta) {
            this.validations = error.response.data.meta.message;
            errorNotification(this, "Error", this.validations.join(", "));
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.image__hint {
  font-size: 10px;
  margin-bottom: 0;
}
.media_file {
  width: 100%;
  height: 300px;
  object-fit: contain;
}
</style>
